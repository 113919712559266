import { useQuery } from '@tanstack/react-query';

import { handleNetworkError } from '../../../../common/components/Notification/notifications';
import { request } from '../../../../common/services/api';
import type { PageInterviewResponse } from '../../../../common/services/api/types';
import { InterviewStatusValue } from '../../../../common/services/api/types';
import { existingParamsToString } from '../../../../common/services/api/utilts';

const GET_CANDIDATE_INTERVIEWS = () =>
  `/candidate/panel/interviews?${existingParamsToString({
    page: '0',
    size: '10000',
    status: [
      InterviewStatusValue.ONGOING,
      InterviewStatusValue.REJECTED_BY_CANDIDATE,
      InterviewStatusValue.REJECTED_BY_RECRUITER,
    ].join(','),
  })}`;

export const getCandidateInterviewsKey = () => ['getCandidateInterviews'];

export const useGetCandidateInterviews = () => {
  return useQuery({
    queryKey: getCandidateInterviewsKey(),
    queryFn: () => request<PageInterviewResponse>(GET_CANDIDATE_INTERVIEWS()),
    onError: async (e) => {
      await handleNetworkError(e, 'Candidate interviews');
    },
  });
};
