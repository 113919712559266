import * as React from 'react';

import NoDataImageUrl from '../../assets/NoData.png';

interface JobNotFoundProps {
  errorMessage: string;
  label?: string;
  textMargin?: string;
}

export function NoData({ errorMessage, label, textMargin }: JobNotFoundProps) {
  const [isImageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div>
      <div className="flex justify-center mt-5">
        <img
          src={NoDataImageUrl}
          alt="Job not found"
          onLoad={handleImageLoad}
        />
      </div>
      {isImageLoaded && (
        <div className="text-center">
          <div
            className={`flex flex-row justify-center ${
              textMargin ?? 'mt-32'
            } font-bold text-xl`}
          >
            {errorMessage}
          </div>
          <div className="flex justify-center text-sm">{label}</div>
        </div>
      )}
    </div>
  );
}
