import { useQuery } from '@tanstack/react-query';

import { handleNetworkError } from '../../../../common/components/Notification/notifications';
import { request } from '../../../../common/services/api';
import type { PageJobResponse } from '../../../../common/services/api/types';
import { existingParamsToString } from '../../../../common/services/api/utilts';

const GET_CANDIDATE_JOBS = (jobsIds: string[]) => {
  return `/candidate/panel/jobs?${existingParamsToString({
    page: '0',
    size: '10000',
    idList: jobsIds.join(','),
  })}`;
};

export const getCandidateJobsKey = (jobsIds: string[]) => [
  'getCandidateJobs',
  jobsIds,
];

export const useGetCandidateJobs = (jobsIds: string[]) => {
  return useQuery({
    enabled: Boolean(jobsIds.length),
    queryKey: getCandidateJobsKey(jobsIds),
    queryFn: () => request<PageJobResponse>(GET_CANDIDATE_JOBS(jobsIds)),
    onError: async (e) => {
      await handleNetworkError(e, 'Candidate jobs');
    },
  });
};
