import React, { useMemo, useState } from 'react';

import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { LoadingSpinner } from '../../../common/components/LoadingSpinner/LoadingSpinner';
import { Modal } from '../../../common/components/Modal/Modal';
import { NoData } from '../../../common/components/NoData/NoData';
import { PageTitle } from '../../../common/components/PageTitle/PageTitle';
import { TrackerListItem } from '../../../common/features/Tracker/components/TrackerListItem';
import { usePrepareAppliedJobsTracker } from './hooks/usePrepareAppliedJobsTracker';

type Mode = 'list' | 'filtering';

export function Tracker() {
  const state = usePrepareAppliedJobsTracker();
  const [mode, setMode] = useState<Mode>('list');
  const [showRejectedJobs, setShowRejectedJobs] = useState(false);

  const items = useMemo(
    () =>
      showRejectedJobs
        ? state.trackerItemData
        : state.trackerItemData.filter((item) => !item.isRejected),
    [state, showRejectedJobs],
  );

  if (state.isLoading) {
    return (
      <div className={'mt-40'}>
        <LoadingSpinner size={50} />
      </div>
    );
  }

  if (!state.trackerItemData.length) {
    return (
      <div className="mt-40">
        <NoData
          errorMessage={'No applications'}
          label={'Sorry! Looks like you submitted no applications yet.'}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-screen p-6">
      <div className="flex flex-row">
        <PageTitle title={'Applied Jobs'} />
        <div
          className="flex bg-backgroundColor-white rounded-md ml-4 w-7 h-7 justify-center items-center hover:bg-backgroundColor-grey shadow-sm"
          onClick={() => setMode('filtering')}
        >
          <FilterIcon />
        </div>
      </div>
      <ul className="mt-6 overflow-auto">
        {items.map((data) => (
          <TrackerListItem key={data.itemId} {...data} />
        ))}
      </ul>
      <Modal
        isOpen={mode === 'filtering'}
        setIsOpen={() => setMode('list')}
        title={'Filters'}
        body={
          <div className="flex flex-row">
            <input
              type="checkbox"
              checked={showRejectedJobs}
              onChange={() => setShowRejectedJobs(!showRejectedJobs)}
              className="h-5 w-5"
            />
            <p className="text-bounties-xl text-textColor-black ml-2">
              Show rejected jobs
            </p>
          </div>
        }
      />
    </div>
  );
}
