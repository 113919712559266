import { Disclosure } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { main_menu } from '../../../../common/services/api/const';
import { ReactComponent as ArrowDown } from '../../../assets/arrowDown.svg';
import { ReactComponent as FreelancerIcon } from '../../../assets/freelancer.svg';
import { useAuthContext } from '../../../providers/auth/AuthProvider';
import { randomUUIDV4 } from '../../../utils/utils';
import type { NavItem } from '../types';
import { MenuItem } from './MenuItem';
import { ProfileMenuItem } from './ProfileMenuItem';

interface SideMenuProps {
  navItems: NavItem[];
  bottomComponents?: JSX.Element;
  onMenuExpand?: (isExpanded: boolean) => void;
}

const mobileSingOut = {
  id: randomUUIDV4(),
  name: 'Sign out',
  href: '/logout',
  logo: <FreelancerIcon />,
};

export function SideMenu({
  navItems,
  bottomComponents,
  onMenuExpand,
}: SideMenuProps) {
  const [{ user }, { logout }] = useAuthContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(
    () => navItems[0].name,
  );
  const onMenuSelected: (item: NavItem) => void = (item) => {
    setSelectedMenu(item.name);
  };

  const handleMenuExpandChange = useCallback(
    (expanded: boolean) => {
      setIsExpanded(expanded);
      onMenuExpand?.(expanded);
    },
    [onMenuExpand],
  );

  const location = useLocation();
  const setMenuWithProperHref = useCallback(() => {
    const properMenu = navItems.find((item) =>
      location.pathname.includes(item.href),
    );
    setSelectedMenu(properMenu?.name ?? null);
  }, [navItems, location]);

  useEffect(() => {
    setMenuWithProperHref();
  }, [location, setMenuWithProperHref]);

  return (
    <div>
      <Disclosure
        as="nav"
        className="bg-backgroundColor-white min-[1023px]:hidden"
      >
        {({ open, close }) => (
          <>
            <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white  focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <div>
                        <div className="flex flex-row">
                          {user?.photoURL ? (
                            <div className="flex w-10 h-10 rounded-md overflow-clip mb-3">
                              <img src={user?.photoURL} alt="User photo" />
                            </div>
                          ) : (
                            <div className="flex w-10 h-10 rounded-md bg-backgroundColor-purple-light items-center justify-center mb-3">
                              <p className="text-textColor-grey-light text-2xl font-semibold">
                                {user?.displayName?.charAt(0)}
                              </p>
                            </div>
                          )}
                          <div className="truncate">
                            <div className="flex flex-row items-center mt-2">
                              <p className="text-textColor-black text-base ml-4 pr-1 font-medium truncate">
                                {user?.displayName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-row">
                          {user?.photoURL ? (
                            <div className="flex w-10 h-10 rounded-md overflow-clip mb-3">
                              <img src={user?.photoURL} alt="User photo" />
                            </div>
                          ) : (
                            <div className="flex w-10 h-10 rounded-md bg-backgroundColor-purple-light items-center justify-center mb-3">
                              <p className="text-textColor-grey-light text-2xl font-semibold">
                                {user?.displayName?.charAt(0)}
                              </p>
                            </div>
                          )}
                          <div className="truncate">
                            <div className="flex flex-row items-center mt-2">
                              <p className="text-textColor-black text-base ml-4 pr-1 font-medium truncate">
                                {user?.displayName}
                              </p>
                              <div className="ml-2">
                                <ArrowDown />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel>
              <div className="px-2 pt-2 pb-3 space-x-1.5">
                {navItems.map((item) => (
                  <MenuItem
                    key={item.href}
                    item={item}
                    isExpanded={true}
                    isSelected={selectedMenu === item.name}
                    onSelect={() => {
                      window.sessionStorage.setItem(main_menu, item.name);
                      setSelectedMenu(item.name);
                      close();
                    }}
                  />
                ))}
                <MenuItem
                  key={'logout'}
                  item={mobileSingOut}
                  isExpanded={true}
                  isSelected={selectedMenu === mobileSingOut.name}
                  onSelect={() => {
                    logout();
                  }}
                />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure
        as="nav"
        className="hidden min-[1023px]:flex w-[72px] hover:w-[242px] transition-[width] duration-[400ms] overflow-clip shrink-0 bg-backgroundColor-white h-full flex flex-col justify-between"
        onMouseEnter={() => handleMenuExpandChange(true)}
        onMouseLeave={() => handleMenuExpandChange(false)}
      >
        <div className="flex flex-col flex-grow ">
          <div className="flex flex-col p-4 pt-5">
            <div className="flex flex-col ">
              <>
                <ProfileMenuItem isExpanded={isExpanded} />
                {navItems.map((item) => (
                  <MenuItem
                    key={item.href}
                    item={item}
                    isExpanded={isExpanded}
                    isSelected={selectedMenu === item.name}
                    onSelect={onMenuSelected}
                  />
                ))}
              </>
            </div>
          </div>
        </div>
        {bottomComponents}
      </Disclosure>
    </div>
  );
}
