import { useEffect } from 'react';

import {
  failureNotification,
  infoNotification,
} from '../../common/components/Notification/notifications';
import { useAuthContext } from '../../common/providers/auth/AuthProvider';
import { invitationLink } from '../../common/services/api/const';
import type { ReferralResponse } from '../../common/services/api/types';
import { useCreateReferral } from '../api/useCreateReferral';
import { useExistingReferrals } from '../api/useExistingRefferals';

export const useReferral = () => {
  const [{ user }] = useAuthContext();
  const invitation = sessionStorage.getItem(invitationLink);

  const referrerId = invitation?.split('/')[5];
  const jobId = invitation?.split('/')[6];
  sessionStorage.setItem('jobId', jobId || '');

  const { mutate } = useCreateReferral();
  const existingReferrals = useExistingReferrals(user?.uid || '');

  const referralExists =
    existingReferrals &&
    existingReferrals.data &&
    existingReferrals.data.content &&
    existingReferrals.data?.content.some(
      (referral: { jobId: string | undefined }) => referral.jobId === jobId,
    );

  const referralUsed =
    existingReferrals &&
    existingReferrals.data &&
    existingReferrals.data.content &&
    existingReferrals.data?.content.some(
      (referral: ReferralResponse) =>
        referral.jobId === jobId &&
        (referral.status.value === 'ACCEPTED' ||
          referral.status.value === 'REJECTED'),
    );

  const referralExpired =
    existingReferrals &&
    existingReferrals.data &&
    existingReferrals.data.content &&
    existingReferrals.data?.content.some(
      (referral: ReferralResponse) =>
        referral.jobId === jobId && referral.status.value === 'EXPIRED',
    );

  const shouldTriggerCreation = referralExists === false;
  const shouldTriggerUsageInfo = referralUsed === true;
  const shouldTriggerExpiredInfo = referralExpired === true;

  useEffect(() => {
    if (referrerId && jobId) {
      if (shouldTriggerCreation) {
        mutate({ referrerId, jobId });
      } else if (shouldTriggerUsageInfo) {
        infoNotification(
          "You've already responded to this job offer. Check applied jobs section for updates.",
        );
      } else if (shouldTriggerExpiredInfo) {
        failureNotification(
          'This job offer has expired. Ask bounty hunter to share new one.',
        );
      }
    }
  }, [
    referrerId,
    jobId,
    mutate,
    shouldTriggerCreation,
    shouldTriggerUsageInfo,
    shouldTriggerExpiredInfo,
  ]);
};
