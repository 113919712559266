import * as React from 'react';

import NotFoundImageUrl from '../../assets/404.png';

interface NotFoundProps {
  errorMessage: string;
  label: string;
}

export function NotFoundPage({ errorMessage, label }: NotFoundProps) {
  const [isImageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div>
      <div className="flex justify-center mt-5">
        <img src={NotFoundImageUrl} alt="Not found" onLoad={handleImageLoad} />
      </div>
      {isImageLoaded && (
        <>
          <div className="flex flex-row justify-center mt-32 font-bold text-xl">
            {errorMessage}
          </div>
          <div className="flex justify-center text-sm">{label}</div>
        </>
      )}
    </div>
  );
}
