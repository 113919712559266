import React from 'react';

import { ReactComponent as AcceptedStepIcon } from '../../../../common/assets/acceptedStep.svg';
import { ReactComponent as OngoingStepIcon } from '../../../../common/assets/ongoingStep.svg';
import { ReactComponent as ReferralStepIcon } from '../../../../common/assets/referralStep.svg';
import type { TrackerStepType } from '../types';

interface TrackerStepIconProps {
  type: TrackerStepType;
  isRed: boolean;
}

export function TrackerStepIcon({ type, isRed }: TrackerStepIconProps) {
  switch (type) {
    case 'referral':
      return (
        <div className="flex items-center justify-center rounded-full w-5 h-5 bg-backgroundColor-grey-dark my-2">
          <ReferralStepIcon />
        </div>
      );
    case 'accepted':
      return (
        <div className="flex items-center justify-center rounded-full w-5 h-5 bg-backgroundColor-grey-dark my-2">
          <AcceptedStepIcon />
        </div>
      );
    case 'ongoing':
      return (
        <div className="flex items-center justify-center rounded-full w-7 h-7 bg-backgroundColor-black my-1">
          <OngoingStepIcon />
        </div>
      );
    case 'pending':
      return (
        <svg height="20" width="20" className="my-2">
          <circle
            cx="10"
            cy="10"
            r="10"
            className="fill-backgroundColor-grey-dark"
          />
          <circle
            cx="10"
            cy="10"
            r="8"
            className={`${
              isRed ? 'fill-red-400' : 'fill-backgroundColor-white'
            }`}
          />
        </svg>
      );
  }
}
