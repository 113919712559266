import React from 'react';

interface MainContentProps {
  children: React.ReactNode;
}

export function MainContent({ children }: MainContentProps) {
  return (
    <div className="p-4 h-[100dvh] min-[1023px]:p-0">
      <div className="mx-auto">{children}</div>
    </div>
  );
}
