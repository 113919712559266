import { useQuery } from '@tanstack/react-query';

import { handleNetworkError } from '../../../../../../common/components/Notification/notifications';
import { request } from '../../../../../../common/services/api';
import { queryClient } from '../../../../../../common/services/api/client';
import type {
  PageJobResponse,
  ReferralStatusValue,
} from '../../../../../../common/services/api/types';

export const invalidateCandidateJobs = async () => {
  await queryClient.invalidateQueries({ queryKey: candidateJobsKey() });
};

// Define the type for the options object
type QueryOptions = {
  idList?: string[];
  statusList?: Array<ReferralStatusValue | string>;
};

// Modify this hook to accept an options object with optional idList and statusList parameters
const candidateJobsKey = (options: QueryOptions = {}) => {
  const key = ['candidate_feed'];
  if (options.idList && options.idList.every((id) => id !== '')) {
    key.push(...options.idList);
  }
  if (
    options.statusList &&
    options.statusList.every((status) => status !== '')
  ) {
    key.push(...options.statusList);
  }
  return key;
};

const CANDIDATE_JOBS = (options: QueryOptions = {}) => {
  let url = `/candidate/panel/jobs?page=0&size=10000000&sort=ASC`; // TODO - for now pagination disabled, by default 2000 elements are returned
  if (options.idList && options.idList.every((id) => id !== '')) {
    const idList = options.idList.join(',');
    url += `&idList=${idList}`; // append idList as a parameter to the request
  }
  if (
    options.statusList &&
    options.statusList.every((status) => status !== '')
  ) {
    const statuses = options.statusList.join(',');
    url += `&statusList=${statuses}`; // append statusList as a parameter to the request
  }
  return url;
};

export const isEnabled = (options: QueryOptions) => {
  if (options.idList && options.statusList) {
    // if both id list and status list are specified, enable when only when all ids and statuses are not empty
    return (
      options.idList.every((id) => id !== '') &&
      options.statusList.every((status) => status !== '')
    );
  }
  if (options.idList) {
    // if id list is specified, enable when only when all ids are not empty
    return options.idList.every((id) => id !== '');
  }
  if (options.statusList) {
    // if status list is specified, enable when only when all ids are not empty
    return options.statusList.every((status) => status !== '');
  }
  return true;
};

export const useCandidateJobs = (options: QueryOptions = {}) =>
  useQuery({
    queryKey: candidateJobsKey(options),
    queryFn: () => request<PageJobResponse>(CANDIDATE_JOBS(options)),
    onError: async (e) => {
      await handleNetworkError(e, 'Candidate job list');
    },
    enabled: isEnabled(options),
  });
