import { API_URL } from '../../config/env';
import { getUserToken } from '../firebase/firebase';

export const request = async <T>(
  url: string,
  opts?: RequestInit,
): Promise<T | null> => {
  const token = await getUserToken();

  const headers = new Headers(opts?.headers);
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const result = await fetch(`${API_URL}${url}`, {
    ...opts,
    headers,
    mode: 'cors',
  });

  if (!result.ok) {
    throw result;
  }

  try {
    const response = await result.json();
    return response;
  } catch (e) {
    // no json response, return null
    return null;
  }
};
