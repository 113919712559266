import type { ReactNode } from 'react';
import { Component } from 'react';

import ErrorImageUrl from '../../assets/error.png';

class ErrorBoundary extends Component<{ children: ReactNode }> {
  state = {
    error: null,
  };

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return (
        <div className="flex flex-col grow h-screen w-screen bg-white">
          <div className="flex flex-row justify-center mt-32 font-bold text-xl">
            ERROR
          </div>
          <div className="flex justify-center text-sm">
            Error occurred contact system administrator -{' '}
            {JSON.stringify(this.state.error)}
          </div>
          <div className="flex justify-center mt-16 mx-16">
            <img src={ErrorImageUrl} alt="Error" />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
