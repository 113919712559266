import React from 'react';

import type { TrackerListItemData } from '../types';
import { TrackerStepListItem } from './TrackerStepListItem';

export function TrackerListItem(data: TrackerListItemData) {
  return (
    <li className="mb-3 flex flex-wrap">
      <div
        className={`h-[90px] pl-8 pr-8 flex ${
          data.isRejected ? 'bg-red-400' : 'bg-backgroundColor-white'
        } rounded-xl shadow-sm items-center`}
      >
        <div className="flex w-64 items-center flex-row">
          <div className="flex flex-col truncate">
            <p className="ml-3 text-bounties-xl font-medium truncate">
              {data.title}
            </p>
            <p className="ml-3 text-bounties font-medium truncate text-textColor-grey">
              {data.subtitle}
            </p>
          </div>
        </div>
        <ul className="flex">
          {data.steps.map((step) => (
            <TrackerStepListItem
              key={step.name}
              step={step}
              isFirstStep={step === data.steps[0]}
              isLastStep={step === data.steps[data.steps.length - 1]}
              isRed={data.isRejected}
            />
          ))}
        </ul>
      </div>
    </li>
  );
}
