import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useUserDetails } from '../../common/api/stale/useUserDetails';
import { LoadingPage } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { useAuthContext } from '../../common/providers/auth/AuthProvider';
import { AuthorizedCandidateLayout } from '../features/AuthorizedLayout/AuthorizedCandidateLayout';
import { useReferral } from '../hooks/useReferral';
import AuthorizedCandidateRoutes from './AuthorizedCandidateRoutes';
import UnauthorizedCandidateRoutes from './UnauthorizedCandidateRoutes';

const CandidateRoutes = () => {
  const [{ user }] = useAuthContext();
  const userDetails = useUserDetails(user?.uid || '');
  useReferral();
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (user) {
      boot({
        email: user.email || '',
        userId: user.uid,
        name: user.displayName || '',
      });
    } else {
      shutdown();
      boot();
    }
  }, [user, boot, shutdown]);

  if (userDetails.isFetching) {
    return <LoadingPage size={150}></LoadingPage>;
  }
  if (user) {
    return (
      <AuthorizedCandidateLayout>
        <AuthorizedCandidateRoutes />
      </AuthorizedCandidateLayout>
    );
  } else {
    return <UnauthorizedCandidateRoutes />;
  }
};

export default CandidateRoutes;
