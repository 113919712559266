import React from 'react';

import {
  isCandidatePanel,
  isHunterPanel,
  isRecruitPanel,
} from '../../config/env';

interface LoadingSpinnerProps {
  size: number;
  label?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size,
  label,
}) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="animate-spin mb-2"
      >
        <div
          className={`h-full w-full border-4 ${
            isRecruitPanel()
              ? 'border-t-purple-500 border-b-purple-700 rounded-[50%]'
              : ''
          } ${
            isHunterPanel()
              ? 'border-t-blue-500 border-b-blue-700 rounded-[50%]'
              : ''
          }
          ${
            isCandidatePanel()
              ? 'border-t-yellow-500 border-b-yellow-600 rounded-[50%]'
              : ''
          } rounded-[50%]`}
        ></div>
      </div>
      {label && <div className="text-sm mt-6">{label}</div>}
    </div>
  );
};

interface LoadingPageProps {
  size: number;
  glassEffect?: boolean;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({
  size,
  glassEffect = false,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center ${
        glassEffect ? 'bg-transparent backdrop-blur-sm' : 'bg-white'
      }`}
    >
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="animate-spin"
      >
        <div
          className={`h-full w-full border-4 ${
            isRecruitPanel()
              ? 'border-t-purple-500 border-b-purple-700 rounded-[50%]'
              : ''
          } ${
            isHunterPanel()
              ? 'border-t-blue-500 border-b-blue-700 rounded-[50%]'
              : ''
          }
          ${
            isCandidatePanel()
              ? 'border-t-yellow-500 border-b-yellow-600 rounded-[50%]'
              : ''
          }`}
        ></div>
      </div>
    </div>
  );
};
