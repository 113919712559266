import type { ReactNode } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import pipelineIcon from '../../../common/assets/dashboard.svg';
import jobsIcon from '../../../common/assets/jobs.svg';
import { MainContent } from '../../../common/features/AuthorizedLayout/components/MainContent';
import { SideMenu } from '../../../common/features/AuthorizedLayout/components/SideMenu';
import type { NavItem } from '../../../common/features/AuthorizedLayout/types';
import { randomUUIDV4 } from '../../../common/utils/utils';

const CandidateNavigationItems: NavItem[] = [
  {
    id: randomUUIDV4(),
    name: 'Job offers feed',
    href: '/jobs',
    logo: <img className="w-6 h-6" src={jobsIcon} alt="Jobs section" />,
  },
  {
    id: randomUUIDV4(),
    name: 'Applied jobs',
    href: '/tracker',
    logo: <img className="w-6 h-6" src={pipelineIcon} alt="Pipeline section" />,
  },
];

interface Props {
  children: ReactNode;
}

export function AuthorizedCandidateLayout(props: Props) {
  const location = useLocation();
  if (location.pathname.includes('/job-applications/new')) {
    return <div>{props.children}</div>;
  }
  return (
    <div className="flex flex-col min-[1023px]:flex-row h-[100dvh] w-screen">
      <SideMenu navItems={CandidateNavigationItems} />
      <main className="flex flex-col h-[100dvh] w-full overflow-hidden bg-backgroundColor-grey-light max-[1023px]:justify-center max-[1023px]:items-center">
        <MainContent>{props.children}</MainContent>
      </main>
    </div>
  );
}
