import { useQuery } from '@tanstack/react-query';

import { handleNetworkError } from '../../common/components/Notification/notifications';
import { request } from '../../common/services/api';
import { queryClient } from '../../common/services/api/client';
import type { PageReferralResponse } from '../../common/services/api/types';
import { existingParamsToString } from '../../common/services/api/utilts';

export const invalidateExistingReferrals = async () => {
  await queryClient.invalidateQueries({ queryKey: referralsKey() });
};

export const REFERRALS = (page = 0, size = 1000000, sort = 'created,DESC') =>
  `/candidate/panel/referrals?${existingParamsToString({
    page: page.toString(),
    size: size.toString(),
    sort,
  })}`;

export const referralsKey = () => ['existing_referrals'];

export const useExistingReferrals = (userId: string) =>
  useQuery({
    queryKey: referralsKey(),
    queryFn: () => request<PageReferralResponse>(REFERRALS()),
    onError: async (e) => {
      await handleNetworkError(e, 'Referral list');
    },
    enabled: userId !== '',
  });
