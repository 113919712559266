import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../../../common/providers/auth/AuthProvider';
import { combineClassNames } from '../../../../common/utils/utils';
import {
  isCandidatePanel,
  isHunterPanel,
  isProduction,
  isRecruitPanel,
} from '../../../config/env';

interface ProfileDropdownMenuProps {
  showBillingOption: boolean;
}

export function ProfileDropdownMenu({
  showBillingOption,
}: ProfileDropdownMenuProps) {
  const [user, { logout }] = useAuthContext();
  const navigate = useNavigate();
  const onBillingClick = useCallback(() => {
    navigate('/billing');
  }, [navigate]);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute left-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        {((isRecruitPanel() && showBillingOption) || isHunterPanel()) && (
          <Menu.Item key={'billing'}>
            <a
              onClick={onBillingClick}
              className={combineClassNames(
                'whitespace-nowrap block text-gray-900 p-4 text-sm hover:text-textColor-grey cursor-pointer font-normal',
              )}
            >
              Billing
            </a>
          </Menu.Item>
        )}
        {(isHunterPanel() || isCandidatePanel()) && (
          <Menu.Item key={'recruit-panel-link'}>
            <a
              onClick={() => {
                const url = isProduction()
                  ? new URL('https://recruit.bounties.app/login')
                  : new URL('https://stage.recruit.bounties.app/login');
                url.searchParams.append('login_hint', user?.user?.email ?? '');

                window.open(url, '_blank');
              }}
              className={combineClassNames(
                'whitespace-nowrap block text-gray-900 p-4 text-sm hover:text-textColor-grey cursor-pointer font-normal  ',
              )}
            >
              Recruit Panel
            </a>
          </Menu.Item>
        )}
        {(isRecruitPanel() || isCandidatePanel()) && (
          <Menu.Item key={'hunter-panel-link'}>
            <a
              onClick={() => {
                const url = isProduction()
                  ? new URL('https://hunter.bounties.app/login')
                  : new URL('https://stage.hunter.bounties.app/login');
                url.searchParams.append('login_hint', user?.user?.email ?? '');

                window.open(url, '_blank');
              }}
              className={combineClassNames(
                'whitespace-nowrap block text-gray-900 p-4 text-sm hover:text-textColor-grey cursor-pointer font-normal  ',
              )}
            >
              Hunter Panel
            </a>
          </Menu.Item>
        )}
        {(isRecruitPanel() || isHunterPanel()) && (
          <Menu.Item key={'candidate-panel-link'}>
            <a
              onClick={() => {
                const url = isProduction()
                  ? new URL('https://candidate.bounties.app/login')
                  : new URL('https://stage.candidate.bounties.app/login');
                url.searchParams.append('login_hint', user?.user?.email ?? '');

                window.open(url, '_blank');
              }}
              className={combineClassNames(
                'whitespace-nowrap block text-gray-900 p-4 text-sm hover:text-textColor-grey cursor-pointer font-normal  ',
              )}
            >
              Candidate Panel
            </a>
          </Menu.Item>
        )}
        <Menu.Item key={'signout'}>
          <a
            onClick={logout}
            className={combineClassNames(
              'whitespace-nowrap block text-gray-900 p-4 text-sm hover:text-textColor-grey cursor-pointer font-normal  ',
            )}
          >
            Sign out
          </a>
        </Menu.Item>
      </Menu.Items>
    </Transition>
  );
}
