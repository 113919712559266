import { Menu } from '@headlessui/react';
import React, { useMemo } from 'react';

import { ReactComponent as ArrowDown } from '../../../../common/assets/arrowDown.svg';
import { isOwner } from '../../../../recruiter/hooks/useOwnerCompanyId';
import { useUserDetails } from '../../../api/stale/useUserDetails';
import { useAuthContext } from '../../../providers/auth/AuthProvider';
import { ProfileDropdownMenu } from './ProfileDropdownMenu';

interface ProfileMenuItemProps {
  isExpanded: boolean;
}

export function ProfileMenuItem({ isExpanded }: ProfileMenuItemProps) {
  const [{ user }] = useAuthContext();
  const userDetails = useUserDetails(user?.uid || '');
  const userIsOwner = useMemo(() => isOwner(userDetails?.data), [userDetails]);

  return (
    <div className="flex flex-row">
      <div>
        {user?.photoURL ? (
          <div className="flex w-10 h-10 rounded-md overflow-clip mb-3">
            <img src={user?.photoURL} alt="User photo" />
          </div>
        ) : (
          <div className="flex w-10 h-10 rounded-md bg-backgroundColor-purple-light items-center justify-center mb-3">
            <p className="text-textColor-grey-light text-2xl font-semibold">
              {user?.displayName?.charAt(0)}
            </p>
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="truncate">
          <Menu as="div">
            <Menu.Button className="p-0 pb-2 pr-2 hover:bg-backgroundColor-grey-light">
              <div className="flex flex-row items-center mt-2">
                <p className="text-textColor-black text-base ml-4 pr-1 font-medium truncate">
                  {user?.displayName}
                </p>
                <div className="ml-2">
                  <ArrowDown />
                </div>
              </div>
            </Menu.Button>
            <ProfileDropdownMenu showBillingOption={userIsOwner} />
          </Menu>
        </div>
      )}
    </div>
  );
}
