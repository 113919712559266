import { LockClosedIcon, XMarkIcon } from '@heroicons/react/20/solid';
import type { FormikHelpers } from 'formik';
import { ErrorMessage, Form, Formik } from 'formik';
import { useCallback } from 'react';
import * as Yup from 'yup';

import Input from '../../components/Input';
import { useAuthContext } from '../../providers/auth/AuthProvider';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('E-mail is required'),
  password: Yup.string().required('Password is required'),
});

interface FormValues {
  email: string;
  password: string;
  persistent: boolean;
}

const InitialValues: FormValues = {
  email: '',
  password: '',
  persistent: false,
};

export function LoginWithEmailAndPassword() {
  const [, { loginWithEmailAndPassword }] = useAuthContext();

  const performLogin = useCallback(
    async (
      { email, password }: FormValues,
      formikBag: FormikHelpers<FormValues>,
    ) => {
      try {
        await loginWithEmailAndPassword(email, password);
      } catch (e) {
        formikBag.setFieldError('submit', 'Invalid credentials');
      }
    },
    [loginWithEmailAndPassword],
  );

  return (
    <>
      <Formik<FormValues>
        onSubmit={performLogin}
        initialValues={InitialValues}
        validationSchema={ValidationSchema}
        validateOnMount
      >
        {({ errors, isValid, isSubmitting }) => (
          <>
            <Form>
              <div className="rounded-3xl">
                <div className="mb-1">
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    className={
                      'relative block w-full appearance-none rounded-t-xl border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm'
                    }
                  />
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="relative block w-full appearance-none rounded-b-xl border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  focus:outline-non sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    <ErrorMessage name="email">
                      {(msg) => (
                        <>
                          <XMarkIcon className="h-5 w-5 text-red-500" /> {msg}
                        </>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    <ErrorMessage name="password">
                      {(msg) => (
                        <>
                          <XMarkIcon className="h-5 w-5 text-red-500" /> {msg}
                        </>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div></div>
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="group relative flex w-full justify-center rounded-3xl border border-transparent bg-backgroundColor-purple-dark py-2 px-4 text-sm font-medium text-white"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                  <p className="text-xl font-bold">Log in</p>
                </button>
              </div>
              {errors && (errors as { submit: string }).submit && (
                <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  <XMarkIcon className="h-5 w-5 text-red-500" />{' '}
                  {(errors as { submit: string }).submit}
                </div>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
