import React from 'react';

import { ReactComponent as BackArrow } from '../../assets/backArrow.svg';

interface Props {
  onClick: () => void;
}

export function BackButton({ onClick }: Props) {
  return (
    <button
      className="w-16 h-16 rounded-full hover:bg-gray-100 bg-backgroundColor-grey-dark flex items-center justify-center p-0"
      onClick={onClick}
    >
      <BackArrow />
    </button>
  );
}
