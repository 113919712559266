export function existingParamsToString(
  params:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined,
) {
  const allParams = new URLSearchParams(params).toString();
  const paramsWithoutEmptyString = allParams
    .replace(/\w+=&/g, '')
    .replace(/&\w+=$/, '');
  return paramsWithoutEmptyString;
}
