import toast from 'react-hot-toast';

export const successNotification = (message: string) =>
  toast.success(message, {
    duration: 6000,
    style: {
      maxWidth: 500,
    },
  });

export const infoNotification = (message: string) =>
  toast(message, {
    duration: 6000,
    style: {
      maxWidth: 500,
    },
  });

export const failureNotification = (message: string) =>
  toast.error(message, {
    duration: 6000,
    style: {
      maxWidth: 500,
    },
  });
export const handleNetworkError = async (
  error: unknown,
  context: string,
  notificationMap?: Record<string, string>,
) => {
  if ((error as unknown as Error).message === 'Failed to fetch') {
    failureNotification('Backend unreachable. Please contact support.');
  }

  try {
    const text = await (error as Response).text();
    const response = JSON.parse(text) as { message: string };
    const message = response.message as keyof typeof notificationMap;
    const notification = notificationMap && notificationMap[message];
    if (notification) {
      failureNotification(
        `${notification}.
              Trace id is ${(error as Response).headers.get('x-trace-id')} `,
      );
    } else {
      failureNotification(
        `${context} network error - ${text}. 
              Trace id is ${(error as Response).headers.get('x-trace-id')} `,
      );
    }
  } catch (e) {
    console.log('ERROR', e);
    failureNotification(
      `${context} network error. 
      Trace id is ${(e as Response).headers.get('x-trace-id')}`,
    );
  }
};
