import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import ErrorBoundary from './common/components/ErrorBoundary';
import { INTERCOM_APP_ID } from './common/config/env';
import { AuthProvider } from './common/providers/auth/AuthProvider';
import Routes from './common/routes/Routes';
import { queryClient } from './common/services/api/client';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const App = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <BrowserRouter>
              <Toaster />
              <Routes />
            </BrowserRouter>
          </IntercomProvider>
        </AuthProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
