import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as GoogleSignIn } from '../../../common/assets/googleSignIn.svg';
import { ReactComponent as LogoLogin } from '../../../common/assets/logoLogin.svg';
import { BackButton } from '../../../common/components/BackButton/BackButton';
import { failureNotification } from '../../../common/components/Notification/notifications';
import { useAuthContext } from '../../../common/providers/auth/AuthProvider';
import { isProduction } from '../../config/env';
import { LoginWithEmailAndPassword } from './LoginWithEmailAndPassword';

interface LoginProps {
  title?: string;
  shouldShowBackButton?: boolean;
}

export function Login({ title, shouldShowBackButton = false }: LoginProps) {
  const [, { googleSignIn }] = useAuthContext();
  const navigate = useNavigate();
  const handleTermsAndPrivacyButtonClick = () => {
    window.open('http://207.154.228.139/privacy/');
  };
  const [isLoginWithEmailAndPassword, setIsLoginWithEmailAndPassword] =
    useState(false);

  const handleNavigationBack = () => navigate(-1);

  function LoginForm() {
    if (isLoginWithEmailAndPassword) {
      return <LoginWithEmailAndPassword />;
    } else {
      return <GoogleLogin />;
    }
  }

  function GoogleLogin() {
    return (
      <button
        onClick={async () => {
          try {
            await googleSignIn();
          } catch (error) {
            if ((error as any).code == 'auth/user-disabled') {
              failureNotification(
                'Your account has been disabled. Please contact support for more information.',
              );
            }
          }
        }}
        className={`flex items-center justify-center mt-10 w-80 h-14 bg-backgroundColor-grey-dark rounded-full text-gray-800 hover:bg-gray-100 focus:outline-none`}
      >
        <GoogleSignIn height={20} width={20} />
        <p className="text-xl px-5 font-medium text-textColor-black">
          Continue with Google
        </p>
      </button>
    );
  }

  return (
    <>
      <div>
        <div className="flex flex-row items-center justify-between m-4">
          {shouldShowBackButton && (
            <BackButton onClick={handleNavigationBack} />
          )}
        </div>
        <div className="flex min-h-full justify-center items-center flex-col bg-cover h-screen pb-32">
          <div className="content-start w-80">
            <LogoLogin />
            <p className="text-xl font-medium mt-12 text-textColor-black">
              {title}
            </p>
          </div>
          {LoginForm()}
          <p className="text-sm text-textColor-grey mt-7">
            By tapping Sin In and using Bounties, you
          </p>
          <p className="text-sm text-textColor-grey">
            agree to our
            <button
              className="p-0.5"
              onClick={handleTermsAndPrivacyButtonClick}
            >
              <b>Terms</b>
            </button>
            and
            <button
              className="p-0.5"
              onClick={handleTermsAndPrivacyButtonClick}
            >
              <b>Privacy Policy.</b>
            </button>
            {!isProduction() && (
              <div className="text-center">
                <button
                  className="p-0.5"
                  onClick={() =>
                    setIsLoginWithEmailAndPassword((current) => !current)
                  }
                >
                  <b>Use different login method</b>
                </button>
              </div>
            )}
          </p>
        </div>
      </div>
    </>
  );
}
