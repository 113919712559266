import { useMemo } from 'react';

import type {
  TrackerListItemData,
  TrackerState,
  TrackerStepListItemData,
} from '../../../../common/features/Tracker/types';
import {
  getApplicationStep,
  getPendingJobSteps,
} from '../../../../common/features/Tracker/utils/pendingCandidatesStepItems';
import { GetInterviewsForHunterStatusItem } from '../../../../common/services/api/types';
import { useGetCandidateApplications } from '../api/useGetCandidateApplications';
import { useGetCandidateInterviews } from '../api/useGetCandidateInterviews';
import { useGetCandidateJobs } from '../api/useGetCandidateJobs';

export function usePrepareAppliedJobsTracker(): TrackerState {
  const { data: applications, isLoading: isLoadingApplications } =
    useGetCandidateApplications();
  const { data: interviews, isLoading: isLoadingInterviews } =
    useGetCandidateInterviews();

  const jobIdList = useMemo(() => {
    const applicationsJobs =
      applications?.content?.map((app) => app.jobData.jobId) ?? [];
    const interviewsJobs =
      interviews?.content?.map((interview) => interview.jobData.jobId) ?? [];
    return [...applicationsJobs, ...interviewsJobs];
  }, [applications, interviews]);

  const { data: jobs, isLoading: isLoadingJobs } =
    useGetCandidateJobs(jobIdList);

  /**
   * This list consists of 2 possible stages:
   * 1. Candidate Applied to the job. Recruiter did not accept it yet or Rejected it.
   * 2. Application was accepted. Interview was created. Candidate is in the interview process or was Denied in it.
   */
  const jobsList = useMemo(() => {
    /**
     * Prepares stage 1.
     */
    const applicationsList: TrackerListItemData[] =
      applications?.content
        ?.filter(
          (application) =>
            application.jobApplicationStatus.value === 'APPLIED' ||
            application.jobApplicationStatus.value === 'REJECTED',
        )
        .flatMap((app) => {
          const job = jobs?.content?.find(
            (job) => job.id === app.jobData.jobId,
          );

          if (!job) {
            return [];
          }

          const jobSteps = getPendingJobSteps(job);
          const applicationStep = getApplicationStep('ongoing', app.created);

          return {
            itemId: app.id,
            title: job.title,
            subtitle: `Referred by ${app.referrer.displayName}`,
            steps: [applicationStep, ...jobSteps],
            isRejected: app.jobApplicationStatus.value === 'REJECTED',
          };
        }) ?? [];

    /**
     * Prepares stage 2.
     */
    const interviewsList: TrackerListItemData[] =
      interviews?.content?.flatMap((interview) => {
        const job = jobs?.content?.find(
          (job) => job.id === interview.jobData.jobId,
        );
        const currentInterviewStep = interview.interviewSteps.find(
          (step) => step.status.value === 'ONGOING',
        );
        const application = applications?.content?.find(
          (app) => app.id === interview.applicationId,
        );

        if (!job || !currentInterviewStep || !application) {
          return [];
        }

        const jobSteps = interview.interviewSteps.map(
          (step): TrackerStepListItemData => {
            const isAccepted = step.order < currentInterviewStep.order;
            const isOngoing = step.order === currentInterviewStep.order;

            return {
              type: isAccepted ? 'accepted' : isOngoing ? 'ongoing' : 'pending',
              name: step.name,
              date: isAccepted || isOngoing ? step.status.updated : null,
            };
          },
        );

        const applicationStep = getApplicationStep(
          'accepted',
          application?.created,
        );

        return {
          itemId: interview.id,
          title: job.title,
          subtitle: `Referred by ${application.referrer.displayName}`,
          steps: [applicationStep, ...jobSteps],
          isRejected:
            interview.status.value ===
              GetInterviewsForHunterStatusItem.REJECTED_BY_CANDIDATE ||
            interview.status.value ===
              GetInterviewsForHunterStatusItem.REJECTED_BY_RECRUITER,
        };
      }) ?? [];

    return [...applicationsList, ...interviewsList];
  }, [jobs, applications, interviews]);

  return {
    isLoading:
      isLoadingApplications ||
      isLoadingInterviews ||
      (isLoadingJobs && Boolean(jobIdList.length)),
    trackerItemData: jobsList,
  };
}
