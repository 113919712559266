import React from 'react';

import CandidateRoutes from '../../candidate/routes/CandidateRoutes';
import { LoadingPage } from '../components/LoadingSpinner/LoadingSpinner';
import { isCandidatePanel, isHunterPanel, isRecruitPanel } from '../config/env';

const HunterRoutes = React.lazy(
  () => import('../../hunter/routes/HunterRoutes'),
);
const RecruiterRoutes = React.lazy(
  () => import('../../recruiter/routes/RecruiterRoutes'),
);

const Routes = () => {
  return (
    <React.Suspense
      fallback={<LoadingPage glassEffect={true} size={150}></LoadingPage>}
    >
      {isRecruitPanel() ? (
        <RecruiterRoutes />
      ) : isHunterPanel() ? (
        <HunterRoutes />
      ) : isCandidatePanel() ? (
        <CandidateRoutes />
      ) : (
        <div>Panel not available</div>
      )}
    </React.Suspense>
  );
};

export default Routes;
