/**
 * Generated by orval v6.14.3 🍺
 * Do not edit manually.
 * Bounties API
 * OpenAPI spec version: 223608e
 */
export type HealthPath200Three = { [key: string]: any };

export type HealthPath200Two = { [key: string]: any };

export type HealthPath200One = { [key: string]: any };

export type Health200Three = { [key: string]: any };

export type Health200Two = { [key: string]: any };

export type Health200One = { [key: string]: any };

export type Links200Three = { [key: string]: { [key: string]: Link } };

export type Links200Two = { [key: string]: { [key: string]: Link } };

export type Links200One = { [key: string]: { [key: string]: Link } };

export type GetAuditParams = {
  since?: string;
  userId?: string;
  pageable: Pageable;
};

export type GetFlaggedJobsParams = {
  pageable: Pageable;
};

export type GetFlaggedUsersParams = {
  pageable: Pageable;
};

export type GetJobApplicationsStatus =
  typeof GetJobApplicationsStatus[keyof typeof GetJobApplicationsStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetJobApplicationsStatus = {
  DRAFT: 'DRAFT',
  APPLIED: 'APPLIED',
  EXPIRED: 'EXPIRED',
  REMOVED: 'REMOVED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

export type GetJobApplicationsParams = {
  applicationId?: string[];
  candidateId?: string;
  jobIdList?: string[];
  status?: GetJobApplicationsStatus;
  pageable: Pageable;
};

export type GetCandidatesParams = {
  userEmail?: string;
  pageable: Pageable;
};

export type GetCompaniesForAdminPanelParams = {
  companyName?: string;
  companyId?: string;
  pageable: Pageable;
};

export type GetHunters1Params = {
  companyId?: string;
  userEmail?: string;
  pageable: Pageable;
};

export type GetInterviewsStatusItem =
  typeof GetInterviewsStatusItem[keyof typeof GetInterviewsStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInterviewsStatusItem = {
  ONGOING: 'ONGOING',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_RECRUITER: 'REJECTED_BY_RECRUITER',
  FINISHED: 'FINISHED',
} as const;

export type GetInterviewsParams = {
  idList?: string[];
  jobIdList?: string[];
  candidateIdList?: string[];
  hunterIdList?: string[];
  applicationIdList?: string[];
  status?: GetInterviewsStatusItem[];
  pageable: Pageable;
};

export type GetJobsStatusItem =
  typeof GetJobsStatusItem[keyof typeof GetJobsStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetJobsStatusItem = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED',
} as const;

export type GetJobsParams = {
  idList?: string[];
  status?: GetJobsStatusItem[];
  companyIdList?: string[];
  creatorIdList?: string[];
  jobTitleList?: string[];
  ownerIdList?: string[];
  pageable: Pageable;
};

export type GetRecruitersParams = {
  companyId?: string;
  userEmail?: string;
  pageable: Pageable;
};

export type SearchUserParams = {
  email?: string;
  userId?: string;
};

export type GetCandidateApplicationsStatusItem =
  typeof GetCandidateApplicationsStatusItem[keyof typeof GetCandidateApplicationsStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCandidateApplicationsStatusItem = {
  DRAFT: 'DRAFT',
  APPLIED: 'APPLIED',
  EXPIRED: 'EXPIRED',
  REMOVED: 'REMOVED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

export type GetCandidateApplicationsParams = {
  applicationIdList?: string[];
  jobIdList?: string[];
  status?: GetCandidateApplicationsStatusItem[];
  pageable: Pageable;
};

export type GetContractsForCandidateStatusItem =
  typeof GetContractsForCandidateStatusItem[keyof typeof GetContractsForCandidateStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetContractsForCandidateStatusItem = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

export type GetContractsForCandidateParams = {
  status?: GetContractsForCandidateStatusItem[];
  pageable: Pageable;
};

export type GetHiredForCandidateParams = {
  hunterId?: string;
  jobId?: string;
  companyId?: string;
  pageable: Pageable;
};

export type GetInterviewsForCandidateStatusItem =
  typeof GetInterviewsForCandidateStatusItem[keyof typeof GetInterviewsForCandidateStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInterviewsForCandidateStatusItem = {
  ONGOING: 'ONGOING',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_RECRUITER: 'REJECTED_BY_RECRUITER',
  FINISHED: 'FINISHED',
} as const;

export type GetInterviewsForCandidateParams = {
  idList?: string[];
  jobIdList?: string[];
  candidateIdList?: string[];
  hunterIdList?: string[];
  applicationIdList?: string[];
  status?: GetInterviewsForCandidateStatusItem[];
  pageable: Pageable;
};

export type GetJobsForCandidateStatusListItem =
  typeof GetJobsForCandidateStatusListItem[keyof typeof GetJobsForCandidateStatusListItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetJobsForCandidateStatusListItem = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export type GetJobsForCandidateParams = {
  idList?: string[];
  jobTitleList?: string[];
  statusList?: GetJobsForCandidateStatusListItem[];
  pageable: Pageable;
};

export type GetReferralsForCandidateStatusListItem =
  typeof GetReferralsForCandidateStatusListItem[keyof typeof GetReferralsForCandidateStatusListItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReferralsForCandidateStatusListItem = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export type GetReferralsForCandidateParams = {
  statusList?: GetReferralsForCandidateStatusListItem[];
  pageable: Pageable;
};

export type GetCompanyRecruitersParams = {
  userEmail?: string;
  pageable: Pageable;
};

export type GetDynamicLinkForHunterAssignmentParams = {
  recruiterId: string;
  jobId: string;
};

export type GetCandidateApplicationsForHunterStatusItem =
  typeof GetCandidateApplicationsForHunterStatusItem[keyof typeof GetCandidateApplicationsForHunterStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCandidateApplicationsForHunterStatusItem = {
  APPLIED: 'APPLIED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

export type GetCandidateApplicationsForHunterParams = {
  applicationIdList?: string[];
  jobIdList?: string[];
  candidateId?: string;
  status?: GetCandidateApplicationsForHunterStatusItem[];
  pageable: Pageable;
};

export type GetJobFeedForCompanyParams = {
  companyId: string;
  pageable: Pageable;
};

export type GetHiredForHunterParams = {
  companyId?: string;
  jobId?: string;
  candidateId?: string;
  pageable: Pageable;
};

export type GetInterviewsForHunterStatusItem =
  typeof GetInterviewsForHunterStatusItem[keyof typeof GetInterviewsForHunterStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInterviewsForHunterStatusItem = {
  ONGOING: 'ONGOING',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_RECRUITER: 'REJECTED_BY_RECRUITER',
  FINISHED: 'FINISHED',
} as const;

export type GetInterviewsForHunterParams = {
  idList?: string[];
  jobIdList?: string[];
  candidateIdList?: string[];
  hunterIdList?: string[];
  applicationIdList?: string[];
  status?: GetInterviewsForHunterStatusItem[];
  pageable: Pageable;
};

export type GetJobsForHunterParams = {
  idList?: string[];
  jobTitleList?: string[];
  pageable: Pageable;
};

export type GetReferralsForHunterStatusListItem =
  typeof GetReferralsForHunterStatusListItem[keyof typeof GetReferralsForHunterStatusListItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReferralsForHunterStatusListItem = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export type GetReferralsForHunterParams = {
  statusList?: GetReferralsForHunterStatusListItem[];
  pageable: Pageable;
};

export type GetMediaUsageLimitForCompanyParams = {
  companyId: string;
  period: string;
};

export type GetMediaUsageForCompanyParams = {
  companyId: string;
  period: string;
};

export type GetCandidateApplicationsForRecruiterStatusItem =
  typeof GetCandidateApplicationsForRecruiterStatusItem[keyof typeof GetCandidateApplicationsForRecruiterStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCandidateApplicationsForRecruiterStatusItem = {
  APPLIED: 'APPLIED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

export type GetCandidateApplicationsForRecruiterParams = {
  applicationIdList?: string[];
  jobIdList?: string[];
  candidateId?: string;
  status?: GetCandidateApplicationsForRecruiterStatusItem[];
  pageable: Pageable;
};

export type GetApplicationDataParams = {
  applicationIdList: string[];
};

export type GetCandidatesForCompanyParams = {
  pageable: Pageable;
};

export type GetContractsForRecruiterStatusItem =
  typeof GetContractsForRecruiterStatusItem[keyof typeof GetContractsForRecruiterStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetContractsForRecruiterStatusItem = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

export type GetContractsForRecruiterParams = {
  status?: GetContractsForRecruiterStatusItem[];
  interviewIdList?: string[];
  pageable: Pageable;
};

export type GetEvaluationSectionsParams = {
  jobIdList: string[];
};

export type GetEvaluationsParams = {
  idList?: string[];
  applicationIdList?: string[];
  recruiterIdList?: string[];
  jobIdList?: string[];
  pageable: Pageable;
};

export type GetHiredForRecruiterParams = {
  hunterId?: string;
  jobId?: string;
  candidateId?: string;
  pageable: Pageable;
};

export type GetHunterDetailsParams = {
  companyId: string;
};

export type GetHuntersParams = {
  companyId: string;
  pageable: Pageable;
};

export type GetInterviewsForRecruiterStatusItem =
  typeof GetInterviewsForRecruiterStatusItem[keyof typeof GetInterviewsForRecruiterStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetInterviewsForRecruiterStatusItem = {
  ONGOING: 'ONGOING',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_RECRUITER: 'REJECTED_BY_RECRUITER',
  FINISHED: 'FINISHED',
} as const;

export type GetInterviewsForRecruiterParams = {
  idList?: string[];
  jobIdList?: string[];
  candidateIdList?: string[];
  hunterIdList?: string[];
  applicationIdList?: string[];
  status?: GetInterviewsForRecruiterStatusItem[];
  pageable: Pageable;
};

export type GetPersonalCommentForCandidateForJobParams = {
  pageable: Pageable;
};

export type GetCommentsForCandidateForJobParams = {
  pageable: Pageable;
};

export type GetCompanyJobsStatusItem =
  typeof GetCompanyJobsStatusItem[keyof typeof GetCompanyJobsStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyJobsStatusItem = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED',
} as const;

export type GetCompanyJobsParams = {
  status?: GetCompanyJobsStatusItem[];
  idList?: string[];
  creatorIdList?: string[];
  jobTitleList?: string[];
  ownerIdList?: string[];
  pageable: Pageable;
};

export type GetHunterStatistics1Params = {
  hunterIdList?: string[];
  pageable: Pageable;
};

export type GetHunterStatisticsParams = {
  hunterIdList?: string[];
  pageable: Pageable;
};

export type GetFormAnswersParams = {
  userId?: string;
};

export type GetFormsParams = {
  externalId?: string;
  id?: string;
};

export type GetCompanyOwnersPageParams = {
  pageable: Pageable;
};

export type CreateSubscriptionPlan =
  typeof CreateSubscriptionPlan[keyof typeof CreateSubscriptionPlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSubscriptionPlan = {
  START: 'START',
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
  PREMIUM500: 'PREMIUM500',
  PREMIUM1000: 'PREMIUM1000',
} as const;

export type CreateSubscriptionParams = {
  plan: CreateSubscriptionPlan;
};

export interface AuditEntryDto {
  commandName: string;
  request: string;
  result: string;
  userId?: string;
  traceId?: string;
  spanId?: string;
  id: string;
  created: string;
}

export interface PageAuditEntryDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AuditEntryDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface Flags {
  flagList: Flag[];
}

export interface FlaggedJobResponse {
  jobId: string;
  jobName: string;
  flags: Flags;
}

export interface PageFlaggedJobResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: FlaggedJobResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type UserDetailsCustomClaims = { [key: string]: string };

export interface UserDetails {
  userId: string;
  email: string;
  displayName?: string;
  phoneNumber?: string;
  photoUrl?: string;
  isDisabled: boolean;
  customClaims: UserDetailsCustomClaims;
  userMetadata: UserMetadata;
}

export interface Flag {
  flaggedBy: string;
  reason: string;
  created: string;
  id: string;
}

export interface FlaggedUserDto {
  user: UserDetails;
  flags: Flag[];
}

export interface PageFlaggedUserDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: FlaggedUserDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface JobApplicationDto {
  id: string;
  candidateId: string;
  jobId: string;
  jobApplicationStatus: JobApplicationStatus;
  details: JobApplicationDetailsDto;
  companyId: string;
  referrerId: string;
  created: string;
  referralId: string;
}

export interface PageJobApplicationDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: JobApplicationDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface AdminPanelCompanyDetailsResponse {
  id: string;
  name: string;
  description?: string;
  recruiters: UserData[];
  owners: UserData[];
  hunters: UserData[];
  logoUrl?: string;
}

export interface AdminPanelCompanyResponse {
  id: string;
  name: string;
  recruitersNum: number;
  hiresNum: number;
  activeJobsNum: number;
  bountiesForActiveJobs: string;
  logoUrl?: string;
  created: string;
}

export interface PageAdminPanelCompanyResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AdminPanelCompanyResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface AdminPanelHunterResponse {
  id: string;
  userData: UserData;
  companies: CompanyData[];
  joined: string;
  isFlagged: boolean;
}

export interface PageAdminPanelHunterResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AdminPanelHunterResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface InterviewDto {
  jobId: string;
  candidateId: string;
  hunterId: string;
  applicationId: string;
  interviewSteps: InterviewStep[];
  status: InterviewStatus;
  id: string;
  updated: string;
  created: string;
  referralId: string;
  currentStep?: InterviewStep;
}

export interface CandidateResponse {
  userData: UserData;
  interviews: InterviewDto[];
  isFlagged: boolean;
}

export interface PageCandidateResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: CandidateResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageInterviewDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: InterviewDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type AdminJobResponseModelItem =
  typeof AdminJobResponseModelItem[keyof typeof AdminJobResponseModelItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminJobResponseModelItem = {
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID',
} as const;

export interface AdminJobResponse {
  id: string;
  companyId: string;
  createdBy: UserData;
  videoAskForm?: VideoAskFormResponse;
  bounty: MoneyDto;
  pendingApplicationCount: number;
  title: string;
  department: string;
  terms: string[];
  model: AdminJobResponseModelItem[];
  budget: string;
  created: string;
  status: JobStatusResponse;
  skills: string[];
  perks: string[];
  location: JobAddress;
  interviewProcessDefinitionDto: InterviewProcessDefinitionDto;
  description: string;
  isFlagged: boolean;
}

export interface PageAdminJobResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AdminJobResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface AdminPanelRecruiterResponse {
  id: string;
  userData: UserData;
  companyData: CompanyData;
  activeJobsNum: number;
  bountiesForActiveJobs: string;
  joined: string;
  isFlagged: boolean;
}

export interface PageAdminPanelRecruiterResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AdminPanelRecruiterResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface UserInCompanyDetailsResponse {
  userId: string;
  recruiterAt: string[];
  ownerAt: string[];
  hunterAt: string[];
}

export interface PageUserData {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: UserData[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface Link {
  value: string;
}

export interface AssignCompanyHunterPayload {
  targetCompanyId: string;
  invitedBy: string;
  jobId?: string;
}

export interface DeepLinkResponseAssignCompanyHunterPayload {
  type: string;
  payload: AssignCompanyHunterPayload;
}

export interface CompanyHunterDynamicLink {
  link: string;
}

export type JobInFeedResponseModelItem =
  typeof JobInFeedResponseModelItem[keyof typeof JobInFeedResponseModelItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobInFeedResponseModelItem = {
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID',
} as const;

export interface JobInFeedResponse {
  id: string;
  formId?: string;
  bounty: JobBountyDto;
  title: string;
  department: string;
  term: string[];
  model: JobInFeedResponseModelItem[];
  budget: string;
  published: JobStatusResponse;
  created: string;
  skills: string[];
  perks: string[];
  location: JobAddress;
  company: CompanyData;
}

export interface PageJobInFeedResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: JobInFeedResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface ReferralResponse {
  referralId: string;
  candidate: UserData;
  jobId: string;
  referrerId: UserData;
  status: ReferralStatus;
  companyId: string;
  created: string;
}

export interface PageReferralResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: ReferralResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface CompanyInvitationPayload {
  invitationId: string;
  active: boolean;
}

export interface DeepLinkResponseCompanyInvitationPayload {
  type: string;
  payload: CompanyInvitationPayload;
}

export interface RecruiterInvitationPayload {
  invitationId: string;
  targetCompanyId: string;
  active: boolean;
}

export interface DeepLinkResponseRecruiterInvitationPayload {
  type: string;
  payload: RecruiterInvitationPayload;
}

export interface UrlAddress {
  url: string;
}

export interface JobOwnerCandidate {
  userId: string;
  displayName?: string;
  photoUrl?: string;
}

export interface MediaUsageLimitDto {
  mediaUsageLimitInSeconds: number;
  yearMonth: string;
}

export interface MediaUsageDto {
  mediaUsageInSeconds: number;
  yearMonth: string;
}

export interface GlobalPaymentSetting {
  id: string;
  bountyFeePercent: number;
  jobPostFee: number;
  createdAt: string;
}

export interface AccountLoginPageLink {
  link: string;
}

export type OnboardingPayloadOnboardingStatus =
  typeof OnboardingPayloadOnboardingStatus[keyof typeof OnboardingPayloadOnboardingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OnboardingPayloadOnboardingStatus = {
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  ONBOARDING: 'ONBOARDING',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
} as const;

export interface OnboardingPayload {
  onboardingStatus: OnboardingPayloadOnboardingStatus;
}

export interface DeepLinkResponseOnboardingPayload {
  type: string;
  payload: OnboardingPayload;
}

export type AccountStatusType =
  typeof AccountStatusType[keyof typeof AccountStatusType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusType = {
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  ONBOARDING: 'ONBOARDING',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
} as const;

export interface AccountStatus {
  type: AccountStatusType;
}

export interface Card {
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

export interface PaymentMethod {
  id: string;
  type: string;
  card: Card;
}

export type SubscriptionPlanResponsePlan =
  typeof SubscriptionPlanResponsePlan[keyof typeof SubscriptionPlanResponsePlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionPlanResponsePlan = {
  START: 'START',
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
  PREMIUM500: 'PREMIUM500',
  PREMIUM1000: 'PREMIUM1000',
} as const;

export interface SubscriptionPlanResponse {
  plan: SubscriptionPlanResponsePlan;
}

export interface PaymentSheet {
  setupIntent: string;
  ephemeralKey: string;
  customer: string;
  publishableKey: string;
}

export interface WebPanelCompanyDataResponse {
  id: string;
  name: string;
  logoUrl?: string;
}

export interface VideoAskFormResponse {
  shareUrl?: string;
  visible: boolean;
}

export interface PageJobApplicationWebPanelResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: JobApplicationWebPanelResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type JobApplicationStatusValue =
  typeof JobApplicationStatusValue[keyof typeof JobApplicationStatusValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobApplicationStatusValue = {
  DRAFT: 'DRAFT',
  APPLIED: 'APPLIED',
  EXPIRED: 'EXPIRED',
  REMOVED: 'REMOVED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
} as const;

export interface JobApplicationStatus {
  value: JobApplicationStatusValue;
  updated: string;
}

export interface JobApplicationWebPanelResponse {
  id: string;
  candidate: UserData;
  jobData: WebPanelJobDataResponse;
  jobApplicationStatus: JobApplicationStatus;
  companyData: WebPanelCompanyDataResponse;
  aboutMe?: string;
  languages: string[];
  skills: string[];
  education: Timeline;
  experience: Timeline;
  model: string[];
  terms: string[];
  salaryExpectation?: string;
  candidateAddress?: CandidateAddress;
  videoAskFormResponse?: VideoAskFormResponse;
  phone?: string;
  referrer: UserData;
  created: string;
  referralId: string;
  score?: number;
  scoreExplanation?: string;
  linkedinLink?: string;
}

export interface ApplicationOverviewDataResponse {
  applicationId: string;
  jobTitle?: string;
  status?: string;
  hunter?: UserData;
  appliedDate: string;
}

export interface CandidateDataDto {
  aboutMe?: string;
  languages: string[];
  skills: string[];
  education: Timeline;
  experience: Timeline;
  model: string[];
  terms: string[];
  salaryExpectation?: string;
  candidateAddress?: CandidateAddress;
  phone?: string;
  linkedinLink?: string;
}

export interface CompanyCandidateResponse {
  userData: UserData;
  candidateData: CandidateDataDto;
  applicationIdList: string[];
}

export interface PageCompanyCandidateResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: CompanyCandidateResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type JobContractStatusValue =
  typeof JobContractStatusValue[keyof typeof JobContractStatusValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobContractStatusValue = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;

export interface JobContractStatus {
  value: JobContractStatusValue;
  updated: string;
}

export interface JobContractDto {
  id: string;
  jobId: string;
  candidateId: string;
  status: JobContractStatus;
  createdAt: string;
  updatedAt: string;
  link?: string;
}

export interface PageJobContractDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: JobContractDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface EvaluationSectionDto {
  id: string;
  name: string;
  jobId: string;
}

export interface EvaluationDto {
  id: string;
  jobId: string;
  applicationId: string;
  recruiterId: string;
  score?: number;
  evaluationSectionName: string;
  evaluationSectionId: string;
}

export interface PageEvaluationDto {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: EvaluationDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type PayoutResponseStatus =
  typeof PayoutResponseStatus[keyof typeof PayoutResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayoutResponseStatus = {
  PENDING: 'PENDING',
  TRANSFERRED: 'TRANSFERRED',
  TRANSFER_FAILED: 'TRANSFER_FAILED',
  REFUNDED: 'REFUNDED',
} as const;

export type PaymentStatusResponseStatus =
  typeof PaymentStatusResponseStatus[keyof typeof PaymentStatusResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatusResponseStatus = {
  CHARGE_PENDING: 'CHARGE_PENDING',
  CHARGED: 'CHARGED',
  TRANSFERRED: 'TRANSFERRED',
  TRANSFER_FAILED: 'TRANSFER_FAILED',
  REFUNDED: 'REFUNDED',
  CHARGE_FAILED: 'CHARGE_FAILED',
} as const;

export interface PaymentStatusResponse {
  status: PaymentStatusResponseStatus;
  transferAfter: string;
  payoutList: PayoutResponse[];
}

export interface Money {
  amount: number;
  currency: string;
}

export interface PayoutResponse {
  user: UserData;
  money: Money;
  status: PayoutResponseStatus;
}

export interface JobData {
  id: string;
  title: string;
}

export interface HiredResponse {
  candidate: UserData;
  hunter: UserData;
  company: CompanyData;
  job: JobData;
  paymentStatus: PaymentStatusResponse;
  createdAt: string;
}

export interface PageHiredResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: HiredResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface ApplicationOverview {
  candidate: UserData;
  step?: string;
  status: string;
  bounty: MoneyDto;
}

export interface HunterDetailsResponse {
  hunter: UserData;
  invitedBy: UserData;
  referralsCount: number;
  hiredCount: number;
  rejectedCount: number;
  appliedCount: number;
  ongoingInterviewCount: number;
  bountiesReceived: MoneyDto;
  potentialBounties: MoneyDto;
  applicationsOverview: ApplicationOverview[];
}

export interface HunterResponse {
  hunter: UserData;
}

export interface PageHunterResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: HunterResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageInterviewResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: InterviewResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface MobileJobDataResponse {
  jobId: string;
  title: string;
  jobAddress: JobAddress;
}

export interface MobileCompanyDataResponse {
  id: string;
  name: string;
  logoUrl?: string;
}

export type InterviewStepStatusValue =
  typeof InterviewStepStatusValue[keyof typeof InterviewStepStatusValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewStepStatusValue = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  ONGOING: 'ONGOING',
} as const;

export interface InterviewStepStatus {
  value: InterviewStepStatusValue;
  updated: string;
}

export interface InterviewStep {
  name: string;
  order: number;
  status: InterviewStepStatus;
}

export type InterviewStatusValue =
  typeof InterviewStatusValue[keyof typeof InterviewStatusValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewStatusValue = {
  ONGOING: 'ONGOING',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_RECRUITER: 'REJECTED_BY_RECRUITER',
  FINISHED: 'FINISHED',
} as const;

export interface InterviewStatus {
  value: InterviewStatusValue;
  updated: string;
}

export interface InterviewResponse {
  id: string;
  candidate: UserData;
  interviewSteps: InterviewStep[];
  status: InterviewStatus;
  updated: string;
  created: string;
  jobData: MobileJobDataResponse;
  companyData: MobileCompanyDataResponse;
  applicationId: string;
  referralId: string;
}

export interface CandidatePersonalCommentEntry {
  commentId: string;
  author: UserData;
  text?: string;
  action?: string;
  createdAt: string;
  updatedAt: string;
}

export interface PageCandidatePersonalCommentEntry {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: CandidatePersonalCommentEntry[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface PageCandidateCommentEntry {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: CandidateCommentEntry[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export type JobPrefillProcessDtoStatus =
  typeof JobPrefillProcessDtoStatus[keyof typeof JobPrefillProcessDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobPrefillProcessDtoStatus = {
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  NOT_STARTED: 'NOT_STARTED',
} as const;

export interface JobPrefillProcessDto {
  jobId: string;
  status: JobPrefillProcessDtoStatus;
  createdAt: string;
}

export type JobStatusResponseValue =
  typeof JobStatusResponseValue[keyof typeof JobStatusResponseValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatusResponseValue = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED',
} as const;

export interface JobStatusResponse {
  value: JobStatusResponseValue;
  changedTime: string;
}

export type JobResponseModelItem =
  typeof JobResponseModelItem[keyof typeof JobResponseModelItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseModelItem = {
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID',
} as const;

export interface CompanyData {
  id: string;
  name: string;
  logoUrl?: string;
}

export interface UserData {
  id: string;
  displayName: string;
  email: string;
  isDisabled: boolean;
  photoUrl?: string;
}

export interface CandidateCommentEntry {
  commentId: string;
  author: UserData;
  text: string;
  parentCommentId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface JobResponse {
  id: string;
  company: CompanyData;
  createdBy: UserData;
  formId?: string;
  bounty: JobBountyDto;
  pendingApplicationCount: number;
  activeInterviewsCount: number;
  title: string;
  department: string;
  terms: string[];
  model: JobResponseModelItem[];
  budget: string;
  created: string;
  status: JobStatusResponse;
  skills: string[];
  perks: string[];
  location: JobAddress;
  interviewProcessDefinitionDto: InterviewProcessDefinitionDto;
  description: string;
}

export interface SortObject {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

export interface PageableObject {
  offset?: number;
  sort?: SortObject;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface PageJobResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: JobResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface HunterHireStatisticResponse {
  hunter: UserData;
  hireCount: number;
  earnedBounties: MoneyDto[];
}

export interface PageHunterHireStatisticResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: HunterHireStatisticResponse[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

export interface Pageable {
  page?: number;
  size?: number;
  sort?: string[];
}

export interface CreateReferralPayload {
  referrerId: string;
  jobId: string;
}

export interface DeepLinkResponseCreateReferralPayload {
  type: string;
  payload: CreateReferralPayload;
}

export interface ReferralDynamicLink {
  link: string;
}

export interface UserMetadata {
  creationTimestamp: number;
  lastSignInTimestamp: number;
  lastRefreshTimestamp: number;
}

export type UserDetailsResponseCustomClaims = { [key: string]: string };

export interface UserDetailsResponse {
  id: string;
  email: string;
  displayName: string;
  phoneNumber?: string;
  photoUrl?: string;
  isDisabled: boolean;
  customClaims: UserDetailsResponseCustomClaims;
  userMetadata: UserMetadata;
}

export type LinkToUploadDtoMetadata = { [key: string]: string };

export interface LinkToUploadDto {
  link: string;
  metadata: LinkToUploadDtoMetadata;
}

export interface LinkToWatch {
  link: string;
}

export interface VideoAskFormAnswerDto {
  id: string;
  formId: string;
  createdBy: string;
  organizationId: string;
  externalId: string;
  shareId: string;
  shareUrl: string;
  createdAt: string;
  duration: number;
  visible: boolean;
}

export interface InvisibleVideoAskFormAnswerDto {
  id: string;
  formId: string;
  createdBy: string;
  organizationId: string;
  externalId: string;
  createdAt: string;
  duration: number;
}

export interface VideoAskFormAnswersResponse {
  answers: VideoAskFormAnswerDto[];
  invisibleAnswers: InvisibleVideoAskFormAnswerDto[];
}

export interface VideoAskQuestion {
  videoAskQuestionId: string;
  mediaType: string;
  mediaUrl: string;
  thumbnail: string;
}

export interface VideoAskQuestions {
  entries: VideoAskQuestion[];
}

export interface VideoAskFormDto {
  id: string;
  createdBy: string;
  organizationId: string;
  externalId: string;
  shareId: string;
  shareUrl: string;
  questions: VideoAskQuestions;
  createdAt: string;
}

export interface EditCommentRequest {
  text: string;
}

export interface FlagJobRequest {
  reason: string;
}

export interface FlagUserRequest {
  reason: string;
}

export interface InvitationForAssigningRecruiterRequest {
  targetCompanyName: string;
  owner: boolean;
  emailTo?: string;
}

export interface ChargeCompanyForBountyRequest {
  payoutBountyId: string;
}

export interface PayoutBountyRequest {
  bountyPayoutId: string;
}

export interface SuspendUserRequest {
  reason: string;
}

export interface UserClaims {
  admin: boolean;
}

export interface CreateUserRequest {
  email: string;
  claims: UserClaims;
  name: string;
}

export interface AddCompanyOwnerRequest {
  userId: string;
}

export interface AddEvaluationEntryRequest {
  score: number;
  evaluationSectionId: string;
}

export interface AddEvaluationsRequest {
  applicationId: string;
  evaluationEntries: AddEvaluationEntryRequest[];
}

export interface AssignHunterToCompanyRequest {
  invitedBy: string;
}

export interface CreateCompanyResponse {
  id: string;
  name: string;
}

export interface CreateCompanyRequest {
  companyName: string;
}

export interface AssignRecruiterResponse {
  userId: string;
  companyId: string;
}

export interface InvitationLink {
  link: string;
}

export interface RecruiterInvitationRequest {
  emailTo?: string;
}

export interface AddPersonalCommentRequest {
  text: string;
  action?: string;
}

export interface AddCommentRequest {
  text: string;
  parentCommentId?: string;
}

export interface AddJobOwnerRequest {
  targetUserId: string;
}

export type JobStatusDtoValue =
  typeof JobStatusDtoValue[keyof typeof JobStatusDtoValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatusDtoValue = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED',
} as const;

export interface JobStatusDto {
  value: JobStatusDtoValue;
  changedTime: string;
}

export interface JobDto {
  id: string;
  createdBy: string;
  companyId: string;
  jobDetails: JobDetailsDto;
  jobBounty: JobBountyDto;
  interviewProcessDefinitionDto: InterviewProcessDefinitionDto;
  created: string;
  status: JobStatusDto;
}

export interface CreateJobRequest {
  jobDetails: JobDetailsDto;
  bounty: JobBountyDto;
  interviewProcessDefinition: InterviewProcessDefinitionDto;
  evaluationSectionNames: string[];
}

export interface UpdateGlobalPaymentSettingsRequest {
  bountyFee: number;
  jobPostFee: number;
  startDate: string;
}

export interface OnboardingLinkResponse {
  onboardingLink: string;
}

export interface LinkWrapper {
  link: string;
}

export type ReferralStatusValue =
  typeof ReferralStatusValue[keyof typeof ReferralStatusValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReferralStatusValue = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export interface ReferralStatus {
  value: ReferralStatusValue;
  updated: string;
}

export interface ReferralDto {
  referralId: string;
  candidateId: string;
  jobId: string;
  referrerId: string;
  status: ReferralStatus;
  companyId: string;
  created: string;
}

export interface ResetUserPasswordRequest {
  email: string;
}

export interface VideoResources {
  public_id: string;
}

export interface VideoStatusDto {
  notification_type: string;
  secure_url?: string;
  tags?: string[];
  public_id?: string;
  resources?: VideoResources[];
  timestamp?: string;
  created_at: string;
}

export interface PollOption {
  id: string;
  content: string;
  ref: string;
}

export interface FormResponseVariables {
  external_id?: string;
  user_id?: string;
  job_id?: string;
  ai_prefill?: boolean;
}

export interface FormResponseEventQuestion {
  allow_multiple_selection?: boolean;
  answers_count?: number;
  collect_contact_information: boolean;
  created_at: string;
  form_id: string;
  label: string;
  media_id: string;
  media_type: string;
  media_url: string;
  question_id: string;
  share_id: string;
  share_url: string;
  thumbnail?: string;
  transcode_status: string;
  transcribe_status: string;
  type: string;
  updated_at: string;
  allowed_answer_media_types?: string[];
}

export interface Form {
  are_answers_public: boolean;
  are_messages_public: boolean;
  author_id: string;
  created_at: string;
  form_id: string;
  hide_branding: boolean;
  idle_deadline_time_in_minutes: number;
  organization_id: string;
  questions: FormResponseEventQuestion[];
  requires_consent: boolean;
  requires_contact_email: boolean;
  requires_contact_info: boolean;
  requires_contact_name: boolean;
  requires_contact_phone_number: boolean;
  requires_contact_product_name: boolean;
  respondents_count: number;
  responses_share_id: string;
  responses_share_url: string;
  share_id: string;
  share_url: string;
  show_consent: boolean;
  show_footnote: boolean;
  show_contact_email: boolean;
  show_contact_name: boolean;
  show_contact_phone_number: boolean;
  show_contact_product_name: boolean;
  tag_share_id: string;
  tag_share_url: string;
  title: string;
  updated_at: string;
  is_password_protected: boolean;
  drop_off_analysis: boolean;
  is_respondent_confirmation_email_enabled: boolean;
  reply_media_time_limit: number;
}

export interface Answer {
  answer_id: string;
  created_at: string;
  is_public: boolean;
  media_duration?: number;
  media_id?: string;
  media_url?: string;
  poll_options: PollOption[];
  file_uploads: string[];
  question_id: string;
  share_id: string;
  share_url: string;
  thumbnail?: string;
  transcode_status?: string;
  transcribe_status?: string;
  transcription?: string;
  type: string;
  poll_option_id?: string;
  poll_option_content?: string;
  input_text?: string;
}

export interface Contact {
  contact_id: string;
  organization_id: string;
  respondent_id?: string;
  name?: string;
  product_name?: string;
  email?: string;
  phone_number?: string;
  thumbnail?: string;
  status: string;
  created_at: string;
  updated_at: string;
  answers: Answer[];
  platform: string;
  tags: string[];
  variables?: FormResponseVariables;
  are_answers_public: boolean;
  are_messages_public: boolean;
  share_id: string;
  share_url: string;
  consent_given?: string;
}

export interface FormResponseEvent {
  event_id: string;
  event_type: string;
  interaction_id: string;
  contact: Contact;
  form: Form;
}

export interface UpdateJobApplicationRequest {
  applicationData: JobApplicationDetailsDto;
}

export interface TimelineRecord {
  from?: string;
  to?: string;
  name: string;
}

export interface Timeline {
  records: TimelineRecord[];
}

export interface CandidateAddress {
  country?: string;
  city?: string;
}

export interface JobApplicationDetailsDto {
  aboutMe?: string;
  languages: string[];
  skills: string[];
  education: Timeline;
  experience: Timeline;
  model: string[];
  terms: string[];
  salaryExpectation?: string;
  candidateAddress?: CandidateAddress;
  videoAskAnswerId?: string;
  phone?: string;
  transcription?: string;
  tags: string[];
  score?: number;
  scoreExplanation?: string;
  linkedinLink?: string;
}

export interface CompanyBasicDataResponse {
  id: string;
  name: string;
  description?: string;
  logoUrl?: string;
}

export interface UpdateCompanyRequest {
  name: string;
  description?: string;
  logoUrl?: string;
}

export interface UpdateEvaluationEntryRequest {
  id: string;
  score?: number;
}

export interface UpdateEvaluationsRequest {
  entries: UpdateEvaluationEntryRequest[];
}

export interface MoneyDto {
  amount: number;
  currencyCode: string;
}

export interface JobBountyDto {
  bounty: MoneyDto;
  payoutAfterTimeInMinutes: number;
}

export interface UpdateJobBountyRequest {
  jobBountyDto: JobBountyDto;
}

export interface ScoreSettingsDto {
  technicalCompatibility: number;
  personalQualities: number;
  logisticalConsiderations: number;
}

export type JobDetailsDtoModelItem =
  typeof JobDetailsDtoModelItem[keyof typeof JobDetailsDtoModelItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobDetailsDtoModelItem = {
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID',
} as const;

export interface JobAddress {
  country: string;
  city: string;
  street: string;
  postCode: string;
}

export interface WebPanelJobDataResponse {
  jobId: string;
  title: string;
  jobAddress: JobAddress;
}

export interface JobDetailsDto {
  title: string;
  department: string;
  location: JobAddress;
  model: JobDetailsDtoModelItem[];
  terms: string[];
  budget: string;
  skills: string[];
  perks: string[];
  description: string;
  screeningQuestions: string[];
  tags: string[];
  summary?: string;
  experience?: string;
  degree?: string;
  formId?: string;
  scoreSettings: ScoreSettingsDto;
}

export interface UpdateJobDetailsRequest {
  jobDetails: JobDetailsDto;
}

export interface UpdateJobInterviewProcessRequest {
  jobInterviewProcessDefinition: InterviewProcessDefinitionDto;
}

export interface InterviewStepDefinitionDto {
  name: string;
  order: number;
}

export interface InterviewProcessDefinitionDto {
  steps: InterviewStepDefinitionDto[];
}
