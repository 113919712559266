import type { User } from '@firebase/auth';
import {
  browserSessionPersistence,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@firebase/auth';

import firebase from './index';

export const auth = getAuth(firebase);

export const googleSignIn = async (): Promise<User | void> => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  const loginHint = getEmailHintFromUrl();
  if (loginHint) {
    provider.setCustomParameters({
      prompt: 'select_account',
      login_hint: decodeURIComponent(loginHint),
    });
  } else {
    provider.setCustomParameters({
      prompt: 'select_account',
    });
  }
  const auth = getAuth();
  const result = await signInWithPopup(auth, provider);
  await auth.setPersistence(browserSessionPersistence);
  return result.user;
};

function getEmailHintFromUrl() {
  const url = new URL(window.location.href);
  const loginHint = url.searchParams.get('login_hint');
  if (loginHint && loginHint.includes('@')) {
    return loginHint;
  }
}

export const loginWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  await auth.setPersistence(browserSessionPersistence);
  return user;
};

export const logout = async () => {
  await auth.signOut();
};

export const getUserToken = async () => {
  if (!auth.currentUser) {
    return null;
  }

  return getIdToken(auth.currentUser);
};
