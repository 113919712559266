import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { LoadingPage } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { NotFoundPage } from '../../common/components/NotFoundPage/NotFoundPage';
import { Tracker } from '../features/Tracker/Tracker';

const JobRoutes = React.lazy(() => import('../features/Job/routes/JobRoutes'));
const JobApplicationRoutes = React.lazy(
  () => import('../features/JobApplication/routes/JobApplicationRoutes'),
);

const AuthorizedCandidateRoutes = () => {
  return (
    <React.Suspense
      fallback={<LoadingPage glassEffect={true} size={150}></LoadingPage>}
    >
      <Routes>
        <Route
          path="*"
          element={
            <NotFoundPage errorMessage={'Not found'} label={'Page not found'} />
          }
        />
        <Route path="login" element={<Navigate to="/jobs/" replace />} />
        <Route path="/" element={<Navigate to="/jobs/" replace />} />
        <Route path="/tracker" element={<Tracker />} />
        <Route path="/jobs/*" element={<JobRoutes />} />
        <Route path="/job-applications/*" element={<JobApplicationRoutes />} />
      </Routes>
    </React.Suspense>
  );
};

export default AuthorizedCandidateRoutes;
