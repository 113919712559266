import type { User } from '@firebase/auth';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type AuthContext = readonly [
  { user: User | null },
  {
    googleSignIn: () => Promise<void>;
    loginWithEmailAndPassword: (
      email: string,
      password: string,
    ) => Promise<void>;
    logout: () => Promise<void>;
  },
];

import {
  auth,
  googleSignIn,
  loginWithEmailAndPassword,
  logout,
} from '../../services/firebase/firebase';

const ctx = createContext<AuthContext | null>(null);

export const useAuthContext = () => {
  const context = useContext(ctx);
  if (!context) {
    throw new Error('Auth context must be initialized!');
  }

  return context;
};

interface Props {
  children: ReactNode;
}
export const AuthProvider = ({ children }: Props) => {
  const [store, setValue] = useState<AuthContext['0']>({
    user: null,
  });
  const [initialized, setInitialized] = useState(false);

  const setUser = useCallback((user: User | null) => {
    setInitialized(true);
    setValue({ user });
  }, []);

  const isLogin = useRef(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // setting of the token will be handled by the login fn
      if (isLogin.current) {
        return;
      }

      // logout
      if (!user) {
        return setUser(null);
      }
      setUser(user);
    });

    return unsubscribe;
  }, [isLogin, setUser]);

  const providerState = useMemo(
    () =>
      [
        store,
        {
          loginWithEmailAndPassword: async (
            email: string,
            password: string,
          ) => {
            isLogin.current = true;
            try {
              const user = await loginWithEmailAndPassword(email, password);
              setValue({ user });
            } finally {
              isLogin.current = false;
            }
          },
          googleSignIn: async () => {
            isLogin.current = true;
            try {
              const user = await googleSignIn();
              if (user) {
                setValue({ user });
              }
            } finally {
              isLogin.current = false;
            }
          },
          logout: async () => {
            await logoutFromApp();
            setValue({ user: null });
          },
        },
      ] as const,
    [store],
  );

  return (
    <ctx.Provider value={providerState}>{initialized && children}</ctx.Provider>
  );
};

export const logoutFromApp = async () => {
  window.sessionStorage.clear();
  await logout();
};
