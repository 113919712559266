import './index.css';

import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { isDeployed } from './common/config/env';

if (isDeployed) {
  Sentry.init({
    dsn: 'https://263757ee15e74dc18bce9e080b712910@o4505402755383296.ingest.sentry.io/4505402756562944',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  LogRocket.init('0vagim/bounties');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
