import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';

import { isHunterPanel, isRecruitPanel } from '../config/env';
import type { UserInCompanyDetailsResponse } from '../services/api/types';

export function combineClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function formatTimestampToDate(
  timeStamp: string | undefined,
  format = 'MMM DD, YYYY',
) {
  if (timeStamp == null) return null;
  const isSecondsTimestamp = timeStamp.length === 13 && /^\d+$/.test(timeStamp);
  if (isSecondsTimestamp) {
    return dayjs(parseFloat(timeStamp)).format(format);
  } else if (timeStamp === '0') {
    return 'Never ';
  } else {
    return dayjs(timeStamp).format(format);
  }
}

export function formatTimestampToDateWithHour(timeStamp: string) {
  const isSecondsTimestamp = timeStamp.length === 13 && /^\d+$/.test(timeStamp);
  if (isSecondsTimestamp) {
    return dayjs(parseFloat(timeStamp)).format('MMM DD, YYYY | HH:MM');
  } else if (timeStamp === '0') {
    return 'Never ';
  }
  {
    return dayjs(timeStamp).format('MMM DD, YYYY | HH:MM');
  }
}

export function getTimeAgo(timestamp?: string) {
  if (timestamp == null) return null;
  const now = new Date();
  const date = new Date(timestamp);
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}min ago`;
  } else {
    return `Just now`;
  }
}

export function isDateInFuture(dateString: string): boolean {
  const convertedDate = new Date(dateString);
  const currentDate = Date.now();

  return convertedDate.getTime() > currentDate;
}

export function getTimeAgoOrInFuture(timestamp?: string) {
  if (timestamp == null) return null;
  if (!isDateInFuture(timestamp)) return getTimeAgo(timestamp);

  const now = new Date();
  const date = new Date(timestamp);
  const inFutureDiff = date.getTime() - now.getTime();
  const seconds = Math.floor(inFutureDiff / 1000) * -1;
  const minutes = Math.floor(seconds / 60) * -1;
  const hours = Math.floor(minutes / 60) * -1;
  const days = Math.floor(hours / 24) * -1;

  if (days > 0) {
    return `in ${days} days`;
  } else if (hours > 0) {
    return `in ${hours}h`;
  } else if (minutes > 0) {
    return `in ${minutes}min`;
  } else {
    return ``;
  }
}

export function randomUUIDV4() {
  return uuidv4();
}

export function currencySymbol(currency: string | undefined) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return currency;
  }
}

export function isHunter(
  userDetails: UserInCompanyDetailsResponse | null | undefined,
) {
  return (
    userDetails?.hunterAt?.length &&
    userDetails?.hunterAt?.length > 0 &&
    isHunterPanel()
  );
}

export function isRecruiter(
  userDetails: UserInCompanyDetailsResponse | null | undefined,
) {
  return (userDetails?.recruiterAt?.length ?? 0) > 0 && isRecruitPanel();
}

export function isFreelancer(
  userName: string,
  displayName: string | null | undefined,
) {
  return userName === displayName;
}

export function amount(
  amount: number,
  currency: string | undefined,
  feeSubtract?: number,
) {
  const feeSubtractValue = 1 - (feeSubtract ?? 0) / 100;
  switch (currency) {
    case 'USD':
      return new BigNumber(amount)
        .dividedBy(100)
        .times(feeSubtractValue)
        .toNumber();
    case 'EUR':
      return new BigNumber(amount)
        .dividedBy(100)
        .times(feeSubtractValue)
        .toNumber();
    default:
      return new BigNumber(amount)
        .dividedBy(100)
        .times(feeSubtractValue)
        .toNumber();
  }
}

export function minutesToFullHours(minutes: number): number {
  return Math.floor(minutes / 60);
}

export type Entity = 'Hunter' | 'Candidate';

export function sanitizedString(dirtyInput: string | null): string | null {
  return dirtyInput && DOMPurify.sanitize(dirtyInput);
}

export function titleCase(str: string) {
  return str
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ');
}
