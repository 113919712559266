import type { JobResponse } from '../../../services/api/types';
import type { TrackerStepListItemData, TrackerStepType } from '../types';

export function getPendingJobSteps(
  job: JobResponse,
): TrackerStepListItemData[] {
  return job.interviewProcessDefinitionDto.steps.map(
    (step): TrackerStepListItemData => {
      return {
        type: 'pending',
        name: step.name,
        date: null,
      };
    },
  );
}

export function getReferralStep(
  type: TrackerStepType,
  date?: string | null,
): TrackerStepListItemData {
  return {
    type: type,
    name: 'Referral',
    //We don't want to show the date for pending steps
    date: type !== 'pending' ? (date ? date : null) : null,
  };
}

export function getApplicationStep(
  type: TrackerStepType,
  date?: string | null,
): TrackerStepListItemData {
  return {
    type: type,
    name: 'Applied',
    //We don't want to show the date for pending steps
    date: type !== 'pending' ? (date ? date : null) : null,
  };
}
