import { initializeApp } from '@firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDxO2G-Hi48pXyAErkzTDKVL_QZ-cdXm5c',
  authDomain: 'bounites-stage.firebaseapp.com',
  projectId: 'bounites-stage',
  storageBucket: 'bounites-stage.appspot.com',
  messagingSenderId: '150137487676',
  appId: '1:150137487676:web:7946d74048c6fc0f545e39',
};

const instance = initializeApp(firebaseConfig);

export default instance;
