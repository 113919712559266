interface PageTitleProps {
  title: string;
}

export function PageTitle({ title }: PageTitleProps) {
  return (
    <div>
      <p className="text-textColor-black text-2xl font-medium">{title}</p>
    </div>
  );
}
