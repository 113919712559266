import React from 'react';
import { NavLink } from 'react-router-dom';

import type { NavItem } from '../types';

interface MenuItemProps {
  item: NavItem;
  isSelected: boolean;
  isExpanded: boolean;
  onSelect: (item: NavItem) => void;
}

export function MenuItem({
  item,
  isSelected,
  isExpanded,
  onSelect,
}: MenuItemProps) {
  return (
    <NavLink
      key={item.id}
      to={item.href}
      className={`${
        isSelected && 'bg-backgroundColor-grey'
      } rounded-md my hover:bg-backgroundColor-grey-light mb-1`}
      onClick={() => onSelect(item)}
      aria-current={isSelected ? 'page' : undefined}
    >
      <div className="flex h-10 items-center ml-2">
        <>
          {item?.logo}
          {isExpanded && (
            <div className="text-black ml-5 text-sm font-semibold">
              {item.name}
            </div>
          )}
        </>
      </div>
    </NavLink>
  );
}
