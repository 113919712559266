import { useQuery } from '@tanstack/react-query';

import { handleNetworkError } from '../../../../common/components/Notification/notifications';
import { request } from '../../../../common/services/api';
import type { PageJobApplicationWebPanelResponse } from '../../../../common/services/api/types';
import { JobApplicationStatusValue } from '../../../../common/services/api/types';
import { existingParamsToString } from '../../../../common/services/api/utilts';

const GET_CANDIDATE_APPLICATIONS = () =>
  `/candidate/panel/applications?${existingParamsToString({
    page: '0',
    size: '10000',
    status: [
      JobApplicationStatusValue.APPLIED,
      JobApplicationStatusValue.ACCEPTED,
      JobApplicationStatusValue.REJECTED,
    ].join(','),
  })}`;

export const getCandidateApplicationsKey = () => ['getCandidateApplications'];

export const useGetCandidateApplications = () => {
  return useQuery({
    queryKey: getCandidateApplicationsKey(),
    queryFn: () =>
      request<PageJobApplicationWebPanelResponse>(GET_CANDIDATE_APPLICATIONS()),
    onError: async (e) => {
      await handleNetworkError(e, 'Candidate applications');
    },
  });
};
