import { useField } from 'formik';
import type { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  metaClassName?: string;
  name: string;
}

const Input = ({ label, name, ...inputProps }: Props) => {
  const [input] = useField(name);

  return (
    <label>
      <div>{label}</div>
      <input {...input} {...inputProps} />
    </label>
  );
};

export default Input;
