import { useQuery } from '@tanstack/react-query';

import {
  handleNetworkError,
  successNotification,
} from '../../components/Notification/notifications';
import { logoutFromApp } from '../../providers/auth/AuthProvider';
import { request } from '../../services/api';
import { queryClient } from '../../services/api/client';
import type { UserInCompanyDetailsResponse } from '../../services/api/types';
import { isHunter, isRecruiter } from '../../utils/utils';

export const USER_WELCOME_BACK_NOTIFICATION_DISABLED_KEY =
  'user_login_notification_disabled';

export const userDetails = () => '/company/user/details';

export const userDetailsKey = (userId: string) => ['user_details', userId];

export const refetchUsersQuery = async () => {
  await queryClient.refetchQueries({ queryKey: ['user_details'] });
};

export const useUserDetails = (userId: string) => {
  return useQuery({
    queryKey: userDetailsKey(userId),
    queryFn: () => request<UserInCompanyDetailsResponse>(userDetails()),
    onError: async (e) => {
      await handleNetworkError(e, 'User details');
      await logoutFromApp();
    },
    onSuccess: async (data) => {
      const wasNotificationShown = sessionStorage.getItem(
        USER_WELCOME_BACK_NOTIFICATION_DISABLED_KEY,
      );
      if (!wasNotificationShown && (isRecruiter(data) || isHunter(data))) {
        successNotification(`Welcome!`);
        sessionStorage.setItem(
          USER_WELCOME_BACK_NOTIFICATION_DISABLED_KEY,
          'true',
        );
      }
    },
    staleTime: Infinity,
    enabled: !!userId,
  });
};
