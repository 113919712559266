import { useUserDetails } from '../../common/api/stale/useUserDetails';
import { useAuthContext } from '../../common/providers/auth/AuthProvider';
import type { UserInCompanyDetailsResponse } from '../../common/services/api/types';

export function useOwnerCompanyId(): string | undefined {
  const [{ user }] = useAuthContext();
  const userDetails = useUserDetails(user?.uid || '');
  return userDetails.data?.ownerAt && userDetails.data?.ownerAt[0];
}

export function isOwner(
  userDetails: UserInCompanyDetailsResponse | null | undefined,
) {
  return (userDetails?.ownerAt?.length ?? 0) > 0;
}
