import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import { Login } from '../../common/features/Login/Login';
import { invitationLink } from '../../common/services/api/const';
import { sanitizedString } from '../../common/utils/utils';

const UnauthorizedCandidateRoutes = () => {
  const [searchParams] = useSearchParams();

  if (searchParams.get('oflLink')) {
    sessionStorage.setItem(
      invitationLink,
      sanitizedString(searchParams.get('oflLink')) || '',
    );
  }
  return (
    <Routes>
      <Route
        path="login"
        element={
          <Login title={'Sign up as candidate to see and apply for a Job'} />
        }
      />
      <Route path="*" element={<Navigate to={'login'} replace />} />
    </Routes>
  );
};

export default UnauthorizedCandidateRoutes;
