export const API_URL = import.meta.env.VITE_API_URL;

export const BASE_URL = import.meta.env.VITE_BASE_URL;

const PANEL_TYPE = import.meta.env.VITE_PANEL_TYPE;
export const INTERCOM_APP_ID = 'q98jss31';

export const STRIPE_URL = 'https://dashboard.stripe.com/';

export const CLOUDINARY_CLOUD_NAME = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME;

export const MODE = import.meta.env.MODE;

export const isDeployed = MODE === 'production';

export const isProduction = () =>
  isDeployed && BASE_URL && !BASE_URL.includes('stage');

const enum PanelType {
  recruit = 'recruit',
  hunter = 'hunter',
  candidate = 'candidate',
}

export const isRecruitPanel = () => PANEL_TYPE === PanelType.recruit;
export const isHunterPanel = () => PANEL_TYPE === PanelType.hunter;
export const isCandidatePanel = () => PANEL_TYPE === PanelType.candidate;
