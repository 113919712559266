import { Dialog, Transition } from '@headlessui/react';
import type { Dispatch } from 'react';
import React, { Fragment } from 'react';

import { ReactComponent as CloseButton } from '../../assets/closeModalButton.svg';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  title: string;
  body: React.ReactNode;
}
export function Modal({ isOpen, setIsOpen, title, body }: ModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-max transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="mb-4 flex flex-row justify-between items-center"
                >
                  <div className={'text-bounties-xl font-medium leading-7'}>
                    {title}
                  </div>
                  <div className="cursor-pointer">
                    <a onClick={() => setIsOpen(false)}>
                      <CloseButton />
                    </a>
                  </div>
                </Dialog.Title>
                {body}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
