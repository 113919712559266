import type { RefObject } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { getTimeAgo, getTimeAgoOrInFuture } from '../../../utils/utils';
import type { TrackerStepListItemData } from '../types';
import { TrackerStepIcon } from './TrackerStepIcon';

interface TrackerStepListItemProps {
  step: TrackerStepListItemData;
  isFirstStep: boolean;
  isLastStep: boolean;
  isRed?: boolean;
}

export function TrackerStepListItem({
  step,
  isFirstStep,
  isLastStep,
  isRed = false,
}: TrackerStepListItemProps) {
  const [isLastStepPadding, setIsLastStepPadding] = useState(50);
  const [isFirstStepPadding, setIsFirstStepPadding] = useState(50);

  const widthRef: RefObject<HTMLParagraphElement> = useRef(null);
  useEffect(() => {
    if (isFirstStep) {
      setIsFirstStepPadding(
        (widthRef.current ? widthRef.current?.offsetWidth : 0) / 2,
      );
    }
    if (isLastStep) {
      setIsLastStepPadding(
        (widthRef.current ? widthRef.current?.offsetWidth : 0) / 2,
      );
    }
  }, [widthRef, isFirstStep, isLastStep]);

  return (
    <li>
      <div className="flex flex-col items-center truncate">
        <div className="relative">
          <div
            className="absolute"
            style={{
              inset: 0,
              left: isFirstStep ? isFirstStepPadding : 0,
              right: isLastStep ? isLastStepPadding : 0,
            }}
          >
            <div className="w-full h-0.5 bg-backgroundColor-grey-dark mt-[17px] z-10" />
          </div>
          <div className="relative z-0">
            <div className="flex items-center justify-center">
              <TrackerStepIcon type={step.type} isRed={isRed} />
            </div>
          </div>
          <p
            ref={widthRef}
            className="justify-center min-w-[24px] px-8 text-bounties font-medium leading-[18px] truncate"
          >
            {step.name}
          </p>
        </div>
        <p className="text-textColor-grey text-[11px] leading-4">
          {step.date && !step.withDateInFuture && getTimeAgo(step.date)}
          {step.date &&
            step.withDateInFuture &&
            getTimeAgoOrInFuture(step.date)}
        </p>
      </div>
    </li>
  );
}
