import { useMutation } from '@tanstack/react-query';

import { handleNetworkError } from '../../common/components/Notification/notifications';
import { request } from '../../common/services/api';
import { invitationLink } from '../../common/services/api/const';
import type { CompanyBasicDataResponse } from '../../common/services/api/types';
import { invalidateCandidateJobs } from '../features/Job/components/JobFeed/api/useCandidateJobs';
import { invalidateExistingReferrals } from './useExistingRefferals';

export const CREATE_REFERRAL = (referrerId: string, jobId: string) =>
  `/referral/${referrerId}/${jobId}`;

export const createReferralKey = () => ['create_referral'];
export const useCreateReferral = () =>
  useMutation({
    mutationKey: createReferralKey(),
    mutationFn: (createReferralRequest: {
      referrerId: string;
      jobId: string;
    }) => {
      return request<CompanyBasicDataResponse>(
        CREATE_REFERRAL(
          createReferralRequest.referrerId,
          createReferralRequest.jobId,
        ),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onError: async (e: unknown) => {
      const textNotificationMap = {
        ['referrer.can.not.refer.itself']:
          'You can not refer yourself to this job. If this is intentional, please use different email address.',
        ['candidate.already.referred.for.job']:
          'Candidate was already referred for this job.',
      };
      await handleNetworkError(e, 'Create referral', textNotificationMap);
    },
    onSuccess: async () => {
      await invalidateExistingReferrals();
      await invalidateCandidateJobs();
      sessionStorage.removeItem(invitationLink);
    },
  });
